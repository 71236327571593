import React from 'react'
import { Translation } from 'react-i18next'

export const ALERTS_COLUMNS = [
  {
    accessor: 'diff_count'
  },
  {
    accessor: 'image'
  },
  { accessor: 'name' },
  { accessor: 'amazon' },
  {
    accessor: 'diffs',
    size: 'large'
  },
  { accessor: 'source', size: 'medium' },
  {
    accessor: 'actions'
  }
]

export const ALERTS_SOURCES = {
  product_tracker: 'Product Tracker',
  my_products: 'My Products'
}

export const ALERTS_LIMIT = 30
export const DELETE_ALERT_MODAL_NAME = 'DeleteAlert'
export const ALERTS_LOCAL_LAST_VIEWED_ID = 'alerts_last_viewed_id'

export const ALERT_FILTER_KEYS = [
  'name',
  'calculatedCategory',
  'imageUrl',
  'length',
  'width',
  'height',
  'weight',
  'rank',
  'nReviews',
  'rating',
  'price',
  'nSellers',
  'isUnavailable',
  'sellerName',
  'brands',
  'asins',
  'map'
]

const filters = {
  productName: {
    accessor: 'name',
    label: (
      <Translation ns="alerts">
        {t => t('alerts:MoreFilters.productName', 'Product Name')}
      </Translation>
    )
  },
  category: {
    accessor: 'calculatedCategory',
    label: (
      <Translation ns="alerts">
        {t => t('alerts:MoreFilters.category', 'Category')}
      </Translation>
    )
  },
  mainImage: {
    accessor: 'imageUrl',
    label: (
      <Translation ns="alerts">
        {t => t('alerts:MoreFilters.image', 'Main Image')}
      </Translation>
    )
  },
  dimensions: {
    accessor: ['length', 'width', 'height'],
    label: (
      <Translation ns="alerts">
        {t => t('alerts:MoreFilters.dimensions', 'Dimensions')}
      </Translation>
    )
  },
  weight: {
    accessor: 'weight',
    label: (
      <Translation ns="alerts">
        {t => t('alerts:MoreFilters.weight', 'Weight')}
      </Translation>
    )
  },
  bsr: {
    accessor: 'rank',
    label: (
      <Translation ns="alerts">
        {t => t('alerts:MoreFilters.rank', 'BSR (Best Seller Ranking)')}
      </Translation>
    )
  },
  reviews: {
    accessor: 'nReviews',
    label: (
      <Translation ns="alerts">
        {t => t('alerts:MoreFilters.reviews', 'Reviews')}
      </Translation>
    )
  },
  rating: {
    accessor: 'rating',
    label: (
      <Translation ns="alerts">
        {t => t('alerts:MoreFilters.rating', 'Rating')}
      </Translation>
    )
  },
  price: {
    accessor: 'price',
    label: (
      <Translation ns="alerts">
        {t => t('alerts:MoreFilters.price', 'Price')}
      </Translation>
    )
  },
  sellers: {
    accessor: 'nSellers',
    label: (
      <Translation ns="alerts">
        {t => t('alerts:MoreFilters.newSeller', '# of Sellers on Listings')}
      </Translation>
    )
  },
  availability: {
    accessor: 'isUnavailable',
    label: (
      <Translation ns="alerts">
        {t =>
          t(
            'alerts:MoreFilters.availability',
            'Availability (Supressed / Out of Stock)'
          )
        }
      </Translation>
    )
  },
  sellerName: {
    accessor: 'sellerName',
    label: (
      <Translation ns="alerts">
        {t => t('alerts:MoreFilters.sellerName', 'Buy Box Changes')}
      </Translation>
    )
  },
  map: {
    accessor: 'map',
    label: (
      <Translation ns="alerts">
        {t =>
          t(
            'alerts:MoreFilters.minAdvertisedPrice',
            'Minimum Advertised Prices'
          )
        }
      </Translation>
    )
  },
  brands: {
    accessor: 'brands',
    label: (
      <Translation ns="alerts">
        {t => t('alerts:MoreFilters.brands', 'Brands')}
      </Translation>
    )
  },
  asins: {
    accessor: 'asins',
    label: (
      <Translation ns="alerts">
        {t => t('alerts:MoreFilters.asins', 'ASINs')}
      </Translation>
    )
  }
}

export const ALERT_LISTING_FILTERS = [
  {
    id: 1,
    filters: [filters.productName, filters.category, filters.mainImage]
  },
  {
    id: 2,
    filters: [filters.dimensions, filters.weight]
  },
  {
    id: 3,
    filters: [filters.bsr, filters.reviews, filters.rating]
  },
  {
    id: 4,
    filters: [filters.price, filters.sellers, filters.availability]
  },
  {
    id: 5,
    filters: [filters.sellerName]
  }
]
