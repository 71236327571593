export const CORE_CODE = 'CORE'
export const COBALT_CODE = 'COBALT'
export const KIT_CODE = 'KIT'
export const JSP_CODE = 'JSP'
export const JSL_CODE = 'JSL'
export const EXTENSION_CODE = 'EXTENSION'

export const PLAN_MEMBERSHIP_CODE_OPTIONS = [CORE_CODE]
export const PLAN_EXTENSION_CODE_OPTIONS = [EXTENSION_CODE]

export const PLAN_CODE_LABELS = {
  [CORE_CODE]: 'ORANGE',
  [KIT_CODE]: 'SELLER',
  [JSP_CODE]: EXTENSION_CODE,
  [JSL_CODE]: EXTENSION_CODE,
  [EXTENSION_CODE]: EXTENSION_CODE
}

export const NUMBER_TYPE_ERROR_MSG = 'Must be a number.'
export const REQUIRED_ERROR_MSG = 'Required'

export const TRACKED_KEYWORDS_LIMIT = 5000
export const ASIN_LIMIT = 10000
export const SEGMENT_LIMIT = 25
export const MEMBERSHIP_ASIN_LIMIT = 10000
export const ASIN_LIMITS_NOTIFICATIONS = true
export const ASIN_CONTRACT_LIMIT_ENFORCEMENT = 1
